.SignUp {}
.signup-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}
@viewport {
  zoom: 1.0;
  min-zoom: 1.0;
  max-zoom: 1.0;
  user-zoom: fixed;
}

/* Adjust styles for small screens (mobile) */
@media screen and (max-width: 600px) {
  .signup-container {
    max-width: 100%;
  }

  #signup {
    text-align: center;
  }

  input {
    width: 100%;
    margin-bottom: 10px;
    touch-action: manipulation;
  }

  button {
    width: 100%;
  }
}
