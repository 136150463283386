.CircleItem {}
.circle-item{ margin: 15px; background-color: white; padding: 8px; border-radius:8px;}
.initials {width: 50px; height:50px; background-color: white; font-size: large; font-weight: 800; border-radius: 25px; border-color: lightgray; border-width: 1px; border-style: solid;}
.initials-name {margin:12.5px;text-transform: uppercase;}
.circle-name {text-transform: capitalize;}
.circle-holder{    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap:15px
}

.colorbubble-green{
    width: 8px;
    height:8px;
    background-color: green;
    border-radius: 4px;
}
.colorbubble-black{
    width: 8px;
    height:8px;
    background-color: black;
    border-radius: 4px;
}
.colorbubble-purple{
    width: 8px;
    height:8px;
    background-color: purple;
    border-radius: 4px;
}
.colorbubble-yellow{
    width: 8px;
    height:8px;
    background-color: yellow;
    border-radius: 4px;
}

.colorborder-green{
    background-color: green;
    border-radius: 4px;
}

.circle-subtitle {
    font-size: x-small;
    color: #a4a4a4;
}
.circle-item-messages{
    text-align: center;
    margin-left: 40px;
    border: 1px solid;
    border-radius: 15px;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    font-size: smaller;
}