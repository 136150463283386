.OnboardingMoodPicker { }
.moodholder{ display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin: auto;

}
.mooditem {
    min-width: 45%;
    text-align: center;

}
.mood-caption{
margin-top: 15px;
}
.mood-selectorbutton{ width: 100px;height: 100px; border-radius: 50px; }
.mood-selectorbutton green{ background-color: green; }
.mood-letsgetstarted{
    width: 100%;
    text-align: center;
}

.mood-selectorbutton-Green{
    width: 100px;height: 100px; border-radius: 50px; 
    background-color:#048c10 ;
    color: white;
}
.mood-selectorbutton-Purple{
    width: 100px;height: 100px; border-radius: 50px; 
    background-color:#61079c ;
    color: white;
}
.mood-selectorbutton-Yellow{
    width: 100px;height: 100px; border-radius: 50px; 
    background-color:#Ffe700 ;
    color: black;
}
.mood-selectorbutton-Dark{
    width: 100px;height: 100px; border-radius: 50px; 
    background-color:#000 ;
    color: #fff;
}