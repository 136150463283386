.UnreadMessages {}

.color-bubble-orange {
    width: 18px;
    height: 18px;
    background-color: rgb(0, 115, 255);
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    margin-left: 30px;
    color:white;
  }
  