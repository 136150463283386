.CircleList {}
.h1cap { text-transform: capitalize;}

.mood-row{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    gap: 10px;
    padding-left: 0px;
}
.colorbubble-green-large{
    width: 14px;
    height:14px;
    border-radius: 7px;
    background-color: green;
}
.colorbubble-black-large{
    width: 14px;
    height:14px;
    border-radius: 7px;
    background-color: black;
   
}
.colorbubble-purple-large{
    width: 14px;
    height:14px;
    border-radius: 7px;
    background-color: purple;

}
.colorbubble-yellow-large{
    width: 14px;
    height:14px;
    border-radius: 7px;
    background-color: yellow;
    
}