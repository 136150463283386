.Chat {}
.chatdiv{
    display: flex;
    align-items: center;
    border-top: 1px solid #f0f0f0;
    /* margin-bottom: 30px; */
    position: fixed;
    bottom: 80px;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 15px;
}
.chat-message-field{
    width: 70%;
    margin-left: 0.5rem;
    margin-right:0.5rem;
}
.chat-send-button{
    width:25%;
    margin-right: 30px;
}