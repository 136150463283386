/* .Footer {bottom: 0;}

.navbar {
    background-color: #333;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;
  } */

  
  .footer-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ff914d   ;
    padding: 15px 0;
  
  }
  
  .footer-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    text-align: center;
  }
  
  .footer-icons a {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: 0 10px;
    color: #fff;
    text-transform: none;
    text-decoration: none;
    justify-items: center;
    text-align: center;
  }
  
  .footer-icons img {
    width: 100%;
    height: 100%;
    
  }
  .icon{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }