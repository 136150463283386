/* App.css */
.App {
  text-align: center;
  margin: 0px;
}

/* Disable zooming */
@viewport {
  zoom: 1.0;
  min-zoom: 1.0;
  max-zoom: 1.0;
  user-zoom: fixed;
}

/* Set touch-action property for input fields */
input {
  touch-action: manipulation;
}

/* Logo styles */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Animation for logo */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Header styles */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Link styles */
.App-link {
  color: #61dafb;
}

/* Keyframes for logo animation */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
